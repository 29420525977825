// PopToast クラス
class PopToast {
  // PopToast コンストラクタ
  constructor() {
    // トーストメッセージのコンテナを作成し、bodyに追加
    this.toastContainer = document.createElement('div');
    this.toastContainer.classList.add('pop-toast-container');
    document.body.appendChild(this.toastContainer);
  }

  // トーストメッセージを表示するメソッド
  show(message, options = {}) {
    // トーストのデフォルトオプション
    const defaultOptions = {
      duration: 3000,
      position: 'top-right',
      closeable: true,
      style: {},
      type: 'success'
    };

    // デフォルトオプションと渡されたオプションをマージ
    options = { ...defaultOptions, ...options };

    // アイコンを定義
    const iconSVGs = {
      success: '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-circle-fill" viewBox="0 0 16 16"><path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/></svg>',
      warning: '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-exclamation-triangle-fill" viewBox="0 0 16 16"><path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/></svg>',
      error: '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-exclamation-circle-fill" viewBox="0 0 16 16"><path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"/></svg>',
      info: '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-info-circle-fill" viewBox="0 0 16 16"><path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"/></svg>'
    }

    // トースト要素を作成し、適切なクラスを追加
    const toast = document.createElement('div');
    toast.classList.add('pop-toast', `pop-toast-${options.type}`, `pop-toast-${options.position}`);

    // アイコン要素を作成し、対応するSVGをinnerHTMLに設定
    const iconElement = document.createElement('div');
    iconElement.classList.add('pop-toast-icon');
    iconElement.innerHTML = iconSVGs[options.type];

    // アイコン要素をトーストに追加
    toast.appendChild(iconElement);

    // カスタムスタイルがあれば適用
    for (let [key, value] of Object.entries(options.style)) {
      toast.style[key] = value;
    }

    // メッセージ要素を作成し、トーストに追加
    const messageElement = document.createElement('div');
    messageElement.classList.add('pop-toast-message');
    messageElement.textContent = message;
    toast.appendChild(messageElement);

    // トーストが閉じ可能であれば、閉じるボタンを追加
    if (options.closeable) {
      const closeButton = document.createElement('button');
      closeButton.classList.add('pop-toast-close');
      closeButton.innerHTML = '&times;';
      closeButton.addEventListener('click', () => {
        this.hide(toast);
      });
      toast.appendChild(closeButton);
    }

    // トースト要素をコンテナに追加
    this.toastContainer.appendChild(toast);

    // 短い遅延の後にトーストをアニメート
    setTimeout(() => {
      toast.classList.add('pop-toast-show');
    }, 0);

    // 指定された期間後にトーストを非表示
    setTimeout(() => {
      this.hide(toast);
    }, options.duration);
  }

  // トーストメッセージを非表示にするメソッド
  hide(toast) {
    // 'toast-hidden'クラスを追加して非表示アニメーションを実行
    toast.classList.remove('pop-toast-show');
    toast.classList.add('pop-toast-hidden');
    // 非表示アニメーションが終了したらDOMからトーストを削除
    toast.addEventListener('transitionend', () => {
      toast.remove();
    });
  }
}

// PopToastをグローバルウィンドウオブジェクトに追加
window.PopToast = PopToast;
